import { createSelector } from "reselect";

import Option from "../models/Option";
import OptionAvailability from "../models/OptionAvailability";
import OptionValidity from "../models/OptionValidity";
import PaymentMethod from "../models/PaymentMethod";

const selectAvailableOffers = (state) => {
  if (!state.subscription.availableOffers.data) {
    return undefined;
  }

  return state.subscription.availableOffers.data;
};

export const selectPpvOffers = (state) => {
  if (!state.subscription.ppvOffers.data) {
    return undefined;
  }

  return state.subscription.ppvOffers.data;
};

export const selectPpvOptions = (state) => {
  if (!state.subscription.ppvOptions.data) {
    return undefined;
  }
  return state.subscription.ppvOptions.data;
};

export const getAvailableOffers = createSelector(selectAvailableOffers, (data) => {
  if (!data || !Array.isArray(data)) {
    return undefined;
  }

  const offers = data.map((option) => {
    return new OptionAvailability(option, "availableOffers");
  });

  return offers;
});

export const selectAvailableOffersByChannelId = (state, channelId) => {
  return createSelector(selectAvailableOffers, (data) => {
    if (!data || !Array.isArray(data)) {
      return undefined;
    }

    let offers = [];
    data.forEach((option) => {
      if (option.objectIds && option.objectIds.channel && option.objectIds.channel.includes(channelId)) {
        offers.push(new OptionAvailability(option, "availableOffers"));
      }
    });

    return offers;
  })(state, channelId);
};

export const selectPpvOffersByChannelId = (state, channelId) => {
  return createSelector(selectPpvOffers, (data) => {
    if (!data || !Array.isArray(data)) {
      return undefined;
    }

    let offers = [];
    const currentTime = new Date().getTime() / 1000;
    data.forEach((option) => {
      if (
        option.objectIds &&
        option.objectIds.channel &&
        option.objectIds.channel.includes(channelId) &&
        option.rightSlot.end > currentTime
      ) {
        offers.push(new OptionAvailability(option, "ppvOffers"));
      }
    });

    return offers;
  })(state, channelId);
};

export const selectAvailableOffersByPackageId = (state, packageIds) => {
  return createSelector(selectAvailableOffers, (data) => {
    if (!data || !Array.isArray(data)) {
      return undefined;
    }

    let offers = [];
    data.forEach((option) => {
      if (
        option.objectIds &&
        option.objectIds.package &&
        option.objectIds.package.find((val) => packageIds.includes(val)) !== undefined
      ) {
        offers.push(new OptionAvailability(option, "availableOffers"));
      }
    });

    return offers;
  })(state, packageIds);
};

const selectAvailableOffersVoucher = (state) => {
  if (!state.subscription.promotions.data) {
    return undefined;
  }

  return state.subscription.promotions.data;
};

export const getAvailableOffersVoucher = createSelector(selectAvailableOffersVoucher, (data) => {
  if (!data || !Array.isArray(data)) {
    return undefined;
  }

  const offers = data.map((option) => {
    return new OptionAvailability(option, "promotions");
  });

  return offers;
});

export const selectListOptions = (state) => {
  if (!state?.subscription?.listOptions.data) {
    return undefined;
  }

  return state.subscription.listOptions.data;
};

export const getListOptions = createSelector(selectListOptions, (data) => {
  if (!data || !Array.isArray(data)) {
    return undefined;
  }

  const options = data.map((option) => {
    return new Option(option, "listOptions");
  });

  return options;
});

export const selectOptionValidity = (state) => {
  if (!state.subscription.optionValidity.data) {
    return undefined;
  }

  return state.subscription.optionValidity.data;
};

export const getOptionValidity = createSelector(selectOptionValidity, (data) => {
  if (!data || !Array.isArray(data)) {
    return undefined;
  }

  const options = data.map((option) => {
    return new OptionValidity(option, "optionValidity");
  });

  return options;
});

const selectListPaymentMethods = (state) => {
  if (!state.subscription.listPaymentMethods.data) {
    return undefined;
  }

  return state.subscription.listPaymentMethods.data;
};

export const getLastUsedPaymentMethod = createSelector(selectListPaymentMethods, (data) => {
  if (!data || !Array.isArray(data)) {
    return undefined;
  }
  const lastUsedPaymentMethod = data.reduce(function (prev, current) {
    return prev?.lastUsage > current?.lastUsage ? prev : current;
  }, {});

  return lastUsedPaymentMethod;
});

export const getListPaymentMethods = createSelector(selectListPaymentMethods, (data) => {
  if (!data || !Array.isArray(data)) {
    return undefined;
  }

  const paymentMethods = data.map((paymentMethod) => {
    return new PaymentMethod(paymentMethod, "listPaymentMethods");
  });

  return paymentMethods;
});
