import { saveInLocal } from "helpers/localStorage";

import actionTypes from "../consts/actionTypes";
import routes from "../consts/routes";

export function createAccount(accountData) {
  const params = {};
  const createAccountBody = [
    "username",
    "email",
    "emailConfirm",
    "password",
    "passwordConfirm",
    "brandId",
    "acceptTerms",
    "firstName",
    "middleName",
    "lastName",
    "smartCardId",
    "title",
    "dateofbirth",
    "languageId",
    "addressStreet",
    "addressNumber",
    "addressZip",
    "addressCountry",
    "addressCity",
    "phoneNumber",
    "addressState",
    "addressBox",
    "mobile",
    "partner",
    "allowNews",
    "additionalInformation",
  ];

  for (let [key, value] of Object.entries(accountData)) {
    if (createAccountBody.includes(key)) {
      params[key] = value;
    }
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.createAccount,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.CREATE_ACCOUNT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.CREATE_ACCOUNT_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.CREATE_ACCOUNT_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function addProducts(productIds) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.addProducts,
          params: {
            productIds: JSON.stringify(productIds),
          },
        },
        onStart: () => {
          return {
            type: actionTypes.ADD_PRODUCTS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ADD_PRODUCTS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.ADD_PRODUCTS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function addOptions(optionIds, productId, contentId, contentType) {
  const params = {
    optionIds: JSON.stringify(optionIds),
    productId: productId,
  };

  if (contentId) {
    params.contentId = contentId;
  }

  if (contentType) {
    params.contentType = contentType;
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.addOptions,
          params,
        },
        onStart: () => {
          return {
            type: actionTypes.ADD_OPTIONS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ADD_OPTIONS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.ADD_OPTIONS_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function removeOptions(optionIds, productId, idReason) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.removeOptions,
          params: {
            optionIds: JSON.stringify(optionIds),
            productId: productId,
            idReason: idReason,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.REMOVE_OPTIONS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          const updatedPayload = {
            payload: payload,
            status: true,
          };

          resolve(updatedPayload);

          return {
            type: actionTypes.REMOVE_OPTIONS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.REMOVE_OPTIONS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function askPayment(paymentData, reusePaymentData, purchasePin, voucher) {
  //console.log(reusePaymentData, purchasePin);
  const params = {
    paymentData: paymentData,
  };

  if (voucher) {
    params.voucher = voucher;
  }
  if (reusePaymentData) {
    params.reusePaymentInfo = reusePaymentData;
  }
  if (purchasePin) {
    params.purchaseCode = purchasePin;
  }
  //console.log("params", params);
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.askPayment,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.ASK_PAYMENT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          const updatedPayload = {
            payload: payload,
            status: true,
          };
          saveInLocal("TucanoOrderId", payload?.orderId);

          resolve(updatedPayload);

          return {
            type: actionTypes.ASK_PAYMENT_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.ASK_PAYMENT_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function askChangeBankInformation(paymentData) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.askChangeBankInformation,
          params: {
            paymentData: paymentData,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.ASK_CHANGE_BANK_INFORMATION_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ASK_CHANGE_BANK_INFORMATION_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.ASK_CHANGE_BANK_INFORMATION_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function ppvOffers(languageId, productId) {
  const params = {
    offerType: "ppv",
  };

  if (languageId) {
    params.languageId = languageId;
  }

  if (productId) {
    params.productId = productId;
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.availableOffers,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.PPV_OFFERS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.PPV_OFFERS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.PPV_OFFERS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function availableOffers(languageId, productId, voucherCode) {
  const params = {};

  if (languageId) {
    params.languageId = languageId;
  }

  if (productId) {
    params.productId = productId;
  }

  if (voucherCode) {
    params.voucherCode = voucherCode;
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.availableOffers,
          params: params,
        },
        onStart: () => {
          return {
            type: voucherCode ? actionTypes.AVAILABLE_OFFERS_VOUCHER_PENDING : actionTypes.AVAILABLE_OFFERS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: voucherCode ? actionTypes.AVAILABLE_OFFERS_VOUCHER_SUCCESS : actionTypes.AVAILABLE_OFFERS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: voucherCode ? actionTypes.AVAILABLE_OFFERS_VOUCHER_ERROR : actionTypes.AVAILABLE_OFFERS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function listOptions(languageId, offerType) {
  const params = {};

  if (languageId) {
    params.languageId = languageId;
  }

  if (offerType) {
    params.offerType = offerType;
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.listOptions,
          params: params,
        },
        onStart: () => {
          return {
            type: offerType === "ppv" ? actionTypes.LIST_PPV_OPTIONS_PENDING : actionTypes.LIST_OPTIONS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: offerType === "ppv" ? actionTypes.LIST_PPV_OPTIONS_SUCCESS : actionTypes.LIST_OPTIONS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: offerType === "ppv" ? actionTypes.LIST_PPV_OPTIONS_ERROR : actionTypes.LIST_OPTIONS_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function activeProducts(token) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const request = {
        method: "POST",
        url: routes.activeProducts,
      };
      if (token) {
        request.headers = {
          "X-AN-WebService-CustomerAuthToken": token,
        };
      }
      dispatch({
        request,
        onStart: () => {
          return {
            type: actionTypes.ACTIVEPRODUCTS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ACTIVEPRODUCTS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.ACTIVEPRODUCTS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function optionValidity(productId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.optionValidity,
          params: {
            productId: productId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.OPTION_VALIDITY_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.OPTION_VALIDITY_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.OPTION_VALIDITY_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function listPaymentMethods(languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.listPaymentMethods,
          params: { languageId: languageId },
        },
        onStart: () => {
          return {
            type: actionTypes.LIST_PAYMENT_METHODS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.LIST_PAYMENT_METHODS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.LIST_PAYMENT_METHODS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function executePayment(paymentData) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.executePayment,
          params: {
            paymentData: paymentData,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.EXECUTE_PAYMENT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          const updatedPayload = {
            payload: payload,
            status: true,
          };

          resolve(updatedPayload);

          return {
            type: actionTypes.EXECUTE_PAYMENT_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          const updatedPayload = {
            payload: payload,
            status: true,
          };

          resolve(updatedPayload);
          return {
            type: actionTypes.EXECUTE_PAYMENT_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function resetOrderStatus() {
  return {
    type: actionTypes.ORDER_STATUS_RESET,
  };
}

export function resetVoucherValidation() {
  return {
    type: actionTypes.RESET_VOUCHER_DATA,
  };
}

export function checkOrderStatus(orderId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.checkOrderStatus,
          params: {
            orderId: orderId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.CHECK_ORDER_STATUS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);

          return {
            type: actionTypes.CHECK_ORDER_STATUS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.CHECK_ORDER_STATUS_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function getBillingDashboardAccess(billingProvider) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.getBillingDashboardAccess,
          params: {
            brandBillingProviderId: billingProvider,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.GET_BILLING_DASHBOARD_ACCESS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);

          return {
            type: actionTypes.GET_BILLING_DASHBOARD_ACCESS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.GET_BILLING_DASHBOARD_ACCESS_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function validateVoucher(voucherCode) {
  return (dispatch) => {
    return new Promise((resolve) => {
      return dispatch({
        request: {
          method: "POST",
          url: routes.validateVoucher,
          params: {
            voucherCode: voucherCode,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.VALIDATE_VOUCHER_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          const updatedPayload = {
            payload: payload,
            status: true,
          };

          resolve(updatedPayload);

          return {
            type: actionTypes.VALIDATE_VOUCHER_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.VALIDATE_VOUCHER_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function listCountries(languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      return dispatch({
        request: {
          method: "POST",
          url: routes.listCountries,
          params: {
            languageId: languageId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.LIST_COUNTRY_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          return {
            type: actionTypes.LIST_COUNTRY_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.LIST_COUNTRY_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function document(objectType, objectId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      return dispatch({
        request: {
          headers: {
            Accept: "application/pdf",
          },
          method: "POST",
          url: routes.document,
          params: {
            objectType: objectType,
            objectId: objectId,
          },
        },
        responseType: "arraybuffer",
        onStart: () => {
          return {
            type: actionTypes.DOCUMENT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.DOCUMENT_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.DOCUMENT_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function setPpvShowState(payload) {
  return {
    type: actionTypes.PPV_OFFERS_SHOW_STATE,
    payload,
  };
}
